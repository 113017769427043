<template>
  <div>
    <div class="share-landing-com-container">
      <!-- 顶部置顶组件 -->
      <div class="share-landing-com-top-warp-bg"></div>
      <div class="share-landing-com-top-warp">
        <img class="top-warp-img__logo" src="@/assets/images/share-content/logo.png" alt="快音" />
        <div class="top-warp-content">
          <div class="top-warp-logo-name">你的好友</div>
          <div class="top-warp-invite-info">邀请你来<span class="name">快音</span>听歌看视频</div>
        </div>
        <div id="com-button-open-app" @click="openApp" class="top-warp-open-btn"><a :href="buttonLink">打开App</a></div>
      </div>
      <!-- 顶部置顶组件  结束-->
      <div class="bg_content">
        <!-- 控件插槽位置 -->
        <slot name="control"></slot>
      </div>
    </div>
    <IntroModal v-if="showIntro" @close="closeIntro" />
  </div>
</template>

<script>
  import Download from '@/utils/download';
  import IntroModal from '@/components/Modal/IntroModal/index.vue';

  export default {
    components: { IntroModal },
    props: {
      musicOrVideoShow: {
        // true 显示音乐 ; false 显示视频播放
        type: Boolean,
        default: true,
      },
      mediaInfo: {
        type: Object,
        default: () => {},
      },
      buttonLink: {
        type: String,
        default: '',
      },
    },
    computed: {},
    data() {
      return {
        showIntro: false,
      };
    },
    destroyed() {},
    beforeMount() {
      this.fissionTrace();
    },
    mounted() {
      let { immediate } = this.$route.query;
      immediate && (this.welcomeModelShow = true);

      // 监听页面各类点击事件 设置弹窗倒计时
      document.addEventListener('click', this.setTimeoutFun, false);
    },
    methods: {
      closeIntro() {
        this.showIntro = false;
        document.body.style.overflow = 'auto';
      },
      /**
         * @description 下载APP
         * @param {string} element_name 节点名称
         * @param {string} element_id 节点id
         * @param {string} remarks remarks
  
         */

      openApp() {
        console.log(`ShareLandingCom openApp`);
        try {
          let jump = () => {
            let url;

            if ((this.$platform.Android || this.$platform.iOS) && (this.$platform.WeiXin || this.$platform.QQ)) {
              // url = Download.downLoad('mb');
              this.showIntro = true;
              document.body.style.overflow = 'hidden';
              return;
            } else if ((this.$platform.Android || this.$platform.iOS) && !this.$platform.WeiXin) {
              url = Download.downLoad('yyb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else if (this.$platform.iOS && !this.$platform.WeiXin) {
              url = Download.downLoad('yyb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
              return;
            } else {
              url = Download.downLoad('yyb');
            }

            window.location.href = url;
          };

          this.$toast({
            message: '亲~正在为你拉起下载页',
            duration: 5000,
            overlay: false,
          });
          // 使用友盟 U-link
          // if (this.UmLink.isUmLinkInitSuccess) {
          //   return;
          // }

          // 无邀请码时，无法 catch 到错误
          //   if (!this.mediaInfo.invite_code) {
          jump();
          // return;
          //   }
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      /**
       * @description @播放音视频
       */

      playMusicOrVideo() {
        this.musicOrVideoShow ? this.changeAudioPlay(true) : this.changeVideoPlay(true);
      },
      /**
       * @description @改变视频状态
       * @param {Boolean} flag 视频状态
       * @return @void
       */

      changeVideoPlay(flag) {
        this.$emit('changeVideoPlay', flag);
      },
      /**
       * @description @改变音频状态
       * @param {Boolean} flag 音乐状态
       */

      changeAudioPlay(flag) {
        console.log(`flag`, flag);
        this.$emit('changeAudioPlay', flag);
      },
    },
  };
</script>

<style lang="less" scoped>
  .share-landing-com-container {
    user-select: none;
    overflow: auto;
    // min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .bg_content {
    background: url('~@/assets/images/share-content/bg.png') no-repeat 100% 100%;
    // padding-top: 1rem;
    background-size: 100% 100%;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
  }
  .share-landing-com-top-warp-bg {
    position: fixed;
    z-index: 1;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    height: 1rem;
  }
  .share-landing-com-top-warp {
    display: flex;
    position: fixed;

    z-index: 91;
    background: rgba(255, 255, 255, 1);
    top: 0;
    left: 0;
    right: 0;
    height: 1rem;
    align-items: center;
    padding: 0 0.4rem;
    .top-warp-content {
      flex: 1;
      .top-warp-logo-name {
        font-size: 0.28rem;
        font-weight: bold;
      }
      .top-warp-invite-info {
        font-size: 0.2rem;
        font-weight: 400;
        color: #878787;
        .name {
          margin: 0 0.1rem;
          color: #ff2b3d;
        }
      }
    }
    .top-warp-img__logo {
      width: 0.72rem;
      height: 0.72rem;
      margin-right: 0.2rem;
    }
    .top-warp-open-btn {
      width: 1.4rem;
      height: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
      font-weight: bold;
      color: #ffffff;
      line-height: 0.24rem;
      background: #ff2b3d;
    }
  }
  a {
    color: #ffffff;
  }
</style>
