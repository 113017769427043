<template>
  <div class="music-control" @click="changeAudioPlay($event)">
    <div class="music-control-content">
      <div class="content-top">
        <div v-if="LyricsList.length > 0" class="item-warp">
          <div
            :class="['lyric-item', currentIndex === k && 'active']"
            v-for="(item, k) in LyricsList"
            :key="k"
            :style="{ top: `${(k + 1 - currentIndex) * 0.6}rem` }"
          >
            {{ item.lyric }}
          </div>
        </div>
        <div class="music-name" v-else>
          {{ musicInfo.is_valid === '1' ? musicInfo.not_valid_txt : musicInfo.title }}
        </div>
      </div>
      <div class="content-bot">
        <div class="lyric-song">
          <div @click.stop="openApp" id="look-song" class="lyric-btn">
            <img id="look-song-img" src="@/assets/images/share-content/ci.png" alt="" />
            <a :href="buttonLink">查看完整歌词</a>
          </div>
          <div @click="openApp" id="set-ring" class="lyric-btn">
            <img id="set-ring-img" src="@/assets/images/share-content/ling.png" alt="" />
            <a :href="buttonLink">设为彩铃</a>
          </div>
        </div>
        <div @click="openApp" id="download-btn-v3" class="download-btn">
          <a :href="buttonLink">下载歌曲</a>
        </div>
      </div>

      <div class="content-right">
        <div @click="openApp" id="focus-dom" class="portrait">
          <a :href="buttonLink">
            <img id="avatar-img" :src="musicInfo.avatar_url" alt="avatar" />
            <div id="avatar-add" class="add">
              <img class="aviator-warp-img__add" src="@/assets/images/share-content/add.png" alt="快音" />
            </div>
          </a>
        </div>
        <div class="handle-content">
          <div @click="openApp" id="music-like" class="handle-item">
            <a :href="buttonLink">
              <img id="music-like-img" src="@/assets/images/share-content/like.png" alt="" />
              <span id="music-like-info" class="info">{{ musicInfo.show_likes }}</span>
            </a>
          </div>
          <div @click="openApp" id="music-dis" class="handle-item">
            <a :href="buttonLink">
              <img id="music-dis-img" src="@/assets/images/share-content/discuss.png" alt="" />
              <span id="music-dis-info" class="info">{{ musicInfo.comments }}</span>
            </a>
          </div>
          <div @click="openApp" id="music-more" class="handle-item">
            <a :href="buttonLink">
              <span id="music-more-info" class="info">更多</span>
            </a>
          </div>
          <div @click="$refs.EmojiWarp.thumbUpClick('', '点赞')" class="handle-item">
            <img id="emoji-img" src="@/assets/images/share-content/dianzan-emo.png" alt="" />
            <span id="emoji-info" class="info">赞赏</span>
          </div>
        </div>
      </div>
    </div>
    <div class="control-warp">
      <template>
        <div v-if="!isAudioPlay" class="control-warp-bg"></div>
        <img v-if="!isAudioPlay" class="music-dire" src="@/assets/images/share-content/stop.png" alt="快音" />
      </template>
    </div>

    <EmojiContent ref="EmojiWarp" />
    <MusicMedia />
  </div>
</template>

<script>
  import EmojiContent from '@/views/Welfare/Fission/ContentLanding/components/EmojiContent';
  import MusicMedia from './MusicMedia';
  import { mapState, mapGetters } from 'vuex';

  export default {
    name: 'MusicControl',
    components: { EmojiContent, MusicMedia },
    props: {
      openApp: {
        type: Function,
        default: () => {},
      },
      isAudioPlay: {
        type: Boolean,
        default: false,
      },
      changeAudioPlay: {
        type: Function,
        default: () => {},
      },
      buttonLink: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        currentIndex: 0, // 当前播放的歌词索引
      };
    },
    computed: {
      ...mapState('share/contentLanding', ['musicInfo']),
      ...mapGetters('share/contentLanding', ['LyricsList']),
    },
    mounted() {
      // 设置监听歌曲播放
      if (this.musicInfo.has_lrc && this.musicInfo.lrc_url) {
        if (!this.musicInfo.play_url) {
          let music_dom = document.getElementById('music_dom');

          this.timeupdateListener(music_dom);
        } else {
          let video_dom = document.getElementById('video_dom');
          this.timeupdateListener(video_dom);
        }
      }
    },
    methods: {
      timeupdateListener(dom) {
        dom.addEventListener('timeupdate', () => {
          let currentTime = dom.currentTime;
          for (let i = 0; i < this.LyricsList.length; i++) {
            if (currentTime >= parseInt(this.LyricsList[i].time)) {
              if (this.LyricsList[i + 1]) {
                if (currentTime < parseInt(this.LyricsList[i + 1].time)) {
                  this.currentIndex = i;
                  break;
                }
              } else {
                // 最后一句
                this.currentIndex = this.LyricsList.length - 1;
                break;
              }
            } else {
              if (currentTime < parseInt(this.LyricsList[0].time)) {
                this.currentIndex = 0;
                break;
              }
            }
          }
        });
      },
    },
  };
</script>

<style lang="less">
  .emo-drop-warp {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .img {
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      left: 5rem;
      z-index: 4;
    }
  }
  @keyframes emoOneAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(80deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(0.5rem, 6rem) rotate(138deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(1rem, 9.4rem) rotate(180deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(1.2rem, 8rem) rotate(226deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(1.5rem, 9.4rem) rotate(280deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(1.7rem, 8.8rem) rotate(313deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(1.9rem, 9.4rem) rotate(360deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(2rem, 9.2rem) rotate(400deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(2.1rem, 9.4rem) rotate(430deg);
      animation-timing-function: ease-out;
    }
  }
  @keyframes emoTwoAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(-94deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(-0.5rem, 6rem) rotate(-178deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(-0.8rem, 9.4rem) rotate(-200deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(-1rem, 7.3rem) rotate(-246deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(-1.3rem, 9.4rem) rotate(-280deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(-1.6rem, 8.5rem) rotate(-323deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(-1.8rem, 9.4rem) rotate(-370deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(-1.9rem, 9rem) rotate(-400deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(-2rem, 9.4rem) rotate(-430deg);
      animation-timing-function: ease-out;
    }
  }
  @keyframes emoThreeAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(30deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(0.5rem, 6.5rem) rotate(88deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(0.8rem, 9.4rem) rotate(130deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(1rem, 8.5rem) rotate(180deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(1.3rem, 9.4rem) rotate(230deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(1.6rem, 8.9rem) rotate(293deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(1.8rem, 9.4rem) rotate(350deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(1.9rem, 9.2rem) rotate(390deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(2rem, 9.4rem) rotate(450deg);
      animation-timing-function: ease-out;
    }
  }
  @keyframes emoFourAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(-84deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(-0.5rem, 6.5rem) rotate(-128deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(-0.6rem, 9.4rem) rotate(-180deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(-1rem, 7.3rem) rotate(-226deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(-1.3rem, 9.4rem) rotate(-270deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(-1.6rem, 8rem) rotate(-303deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(-1.8rem, 9.4rem) rotate(-340deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(-1.9rem, 9.2rem) rotate(-370deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(-2rem, 9.4rem) rotate(-420deg);
      animation-timing-function: ease-out;
    }
  }
</style>

<style lang="less" scoped>
  @cardBackground: rgba(0, 0, 0, 0.3);
  .music-control {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;

    .control-warp {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-0.8rem, -0.8rem);
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      z-index: 3;
      .control-warp-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000;
        border-radius: 50%;
        opacity: 0.5;
      }
      .music-dire {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-0.6rem, -0.6rem);
        width: 1.2rem;
        height: 1.2rem;
        z-index: 1;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      bottom: 0;
      box-sizing: border-box;
      z-index: 2;

      background: linear-gradient(180deg, rgba(42, 44, 45, 0) 0%, rgba(42, 44, 45, 0.8) 100%);
      .content-top {
        height: 1.7rem;
        overflow: hidden;
        position: relative;
        .music-name {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 0.4rem;
          color: #fff;
          font-weight: bold;
        }
        .item-warp {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
        }
        .lyric-item {
          font-size: 0.28rem;
          color: #ffffff;
          line-height: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-shadow: 0 0.04rem 0.04rem rgba(0, 0, 0, 0.5);
          filter: blur(0.01rem);
          flex-wrap: nowrap;
          flex: 1;
          position: absolute;
          transition: top 0.5s;
          &.active {
            font-size: 0.36rem;
            font-weight: bold;
            filter: none;
            text-shadow: none;
          }
        }
      }
      .content-bot {
        display: flex;
        flex-direction: column;
        align-items: center;
        .lyric-song {
          display: flex;
          justify-content: center;
          margin-top: 0.2rem;
          .lyric-btn {
            margin: 0 0.1rem;
            display: flex;
            align-items: center;
            font-size: 0.24rem;
            padding: 0.1rem;
            border-radius: 0.28rem;
            background: rgba(0, 0, 0, 0.3);
            color: #ffffff;
            img {
              margin-right: 0.06rem;
              width: 0.32rem;
              height: 0.32rem;
            }
          }
        }
        .download-btn {
          width: 3.96rem;
          height: 0.8rem;
          border-radius: 0.4rem;
          color: #fff;
          border: 0.02rem solid #ffffff;
          font-size: 0.32rem;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0.3rem 0 0.4rem;
        }
      }
      .content-right {
        position: absolute;
        right: 0.4rem;
        top: -0.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .handle-content {
          margin-top: 0.14rem;
          .handle-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #fff;
            font-size: 0.26rem;
            font-weight: bold;
            margin-top: 0.14rem;
            img {
              width: 0.48rem;
              height: 0.48rem;
            }
          }
        }
        .portrait {
          position: relative;
          img {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
          }
          .add {
            width: 0.25rem;
            height: 0.25rem;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -0.1rem;
            color: #fff;
            display: flex;
            justify-content: center;
            //align-items: center;
            background: #ff2b3d;
            img {
              width: 0.24rem;
              height: 0.24rem;
            }
          }
        }
      }
    }
  }
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
  }
</style>
