<template>
  <ShareLandingCom
    ref="ShareLandingCom"
    v-if="musicInfo.cdn_url"
    @changeAudioPlay="changeAudioPlay"
    :musicOrVideoShow="true"
    :buttonLink="buttonLink"
  >
    <template slot="control">
      <MusicControl
        :changeAudioPlay="changeAudioPlay"
        :isAudioPlay="isAudioPlay"
        :openApp="openApp"
        :buttonLink="buttonLink"
      />
    </template>
  </ShareLandingCom>
</template>

<script>
  import ShareLandingCom from './components/ShareLandingCom';
  import MusicControl from './components/MusicControl';
  import { mapGetters, mapState, mapMutations } from 'vuex';

  export default {
    components: { ShareLandingCom, MusicControl },
    data() {
      return {
        isAudioPlay: false,
        buttonLink: 'javascript:void(0);',
      };
    },
    computed: {
      ...mapState('share/contentLanding', ['musicInfo', 'mediaInfo']),
      ...mapGetters('share/contentLanding', [
        'MusicContentAb',
        'OriginLyricsList',
        'UmLink',
        'PageTitle',
        'QuickAppInfo',
      ]),
    },

    async asyncData({ store, $http, params }) {
      console.log(`params`, params);
      const data = await $http.post(
        '/music/detail',
        {
          ...params,
        },
        {
          client_v: '5.40.00',
          device_id: 'd6d6cefbd1d9d861',
          platform: 'Android',
        }
      );
      console.log(`data`, data);
      const {
        music_info: {
          title,
          cdn_url,
          cover,
          is_valid,
          not_valid_txt,
          show_likes,
          comments,
          tags,
          background,
          has_lrc,
          lrc_url,
        },
        music_user: { nickname, avatar_url },
        video_info: { play_url, video_cover },
      } = data;
      const musicInfo = {
        title,
        cdn_url,
        nickname,
        avatar_url,
        cover,
        is_valid,
        not_valid_txt,
        video_cover,
        play_url,
        //   avatar_small,
        show_likes,
        comments,
        tags,
        background,
        has_lrc,
        lrc_url,
      };
      //   let page_title;
      //   switch (music_content_ab) {
      //     case 'a':
      //       page_title = '内容分享落地页';
      //       break;
      //     case 'b':
      //       page_title = '内容落地页迭代';
      //       break;
      //     case 'c':
      //       page_title = '内容落地页3';
      //       break;
      //     case 'd':
      //       page_title = '内容落地页4';
      //       break;
      //     default:
      //       page_title = '内容分享落地页';
      //       break;
      //   }
      //   store.commit('share/contentLanding/SET_PAGE_TITLE', page_title);
      store.commit('share/contentLanding/SET_MUSIC_INFO', musicInfo);
      // store.commit('share/contentLanding/SET_MEDIA_INFO', mediaInfo);
      //   store.commit('share/contentLanding/SET_MUSIC_CONTENT_AB', music_content_ab);
      //   store.commit('share/contentLanding/SET_QUICK_APP_INFO', quick_app);

      // 获取歌词
      if (has_lrc && lrc_url) {
        const lyricsData = await $http.get(lrc_url);
        store.commit('share/contentLanding/SET_ORIGIN_LYRICS_LIST', lyricsData.data);
      }
    },

    mounted() {
      if (this.$platform.Android && !this.$platform.WeiXin) {
        const SCHEME =
          this.$route.query.app_name && this.$route.query.app_name !== 'undefined'
            ? this.$route.query.app_name
            : 'kuaiyin';

        this.buttonLink = `${SCHEME}://videoDetail?id=${this.$route.query.music_code}`;
      } else {
        this.buttonLink = 'javascript:void(0);';
      }
      if (this.musicInfo.has_lrc && this.musicInfo.lrc_url) {
        this.initPoem();
      }
      window.addEventListener('click', this.clickCallBack);
      this.$on('hook:beforeDestroy', () => {
        window.removeEventListener('click', this.clickCallBack);
      });
      const sensorsData = JSON.parse(this.getCookie('sensorsdata2015jssdkcross'));
      this.$http.TaskPost('/ShareMusicReward/Report', {
        music_code: this.$route.query.music_code,
        share_from: this.$route.query.share_from,
        distinct_id: sensorsData.distinct_id,
        business: 'music',
      });
    },

    methods: {
      ...mapMutations('share/contentLanding', ['SET_LYRICS_LIST']),
      async initPoem() {
        try {
          // 解析歌词
          if (this.OriginLyricsList) {
            const LyricsList = this.OriginLyricsList.split('\n');
            const regTime = /\[\d{2}:\d{2}.\d{2,3}/;
            const lyricsObjArr = [];

            LyricsList.forEach((item) => {
              if (item === '') return;
              const obj = {};
              const time = item.match(regTime);

              obj.lyric = item.split(']')[1].trim() === '' ? '' : item.split(']')[1].trim();
              obj.time = time ? this.formatLyricTime(time[0].slice(1, time[0].length - 1)) : 0;
              if (obj.lyric === '') {
                console.log('这一行没有歌词');
              } else {
                lyricsObjArr.push(obj);
              }
            });
            this.SET_LYRICS_LIST(lyricsObjArr);
          }
        } catch (e) {
          console.log('歌词解析出错~');
        }
      },
      formatLyricTime(time) {
        // 格式化歌词的时间 转换成 sss:ms
        const regMin = /.*:/;
        const regSec = /:.*\./;
        const regMs = /\./;

        const min = parseInt(time.match(regMin)[0].slice(0, 2));
        let sec = parseInt(time.match(regSec)[0].slice(1, 3));
        const ms = time.slice(time.match(regMs).index + 1, time.match(regMs).index + 3);
        if (min !== 0) {
          sec += min * 60;
        }
        return Number(sec + '.' + ms);
      },
      /**
       * @description @改变音频状态
       * @data @Boolean
       * @return @void
       */

      changeAudioPlay(arg) {
        let isNeedReport = (arg.target && !arg.target.id) || arg === true || arg.target.id === 'video_dom';
        if (!this.musicInfo.play_url) {
          let music_dom = document.getElementById('music_dom');
          if (music_dom.paused) {
            if (isNeedReport) {
              this.$track({ page_title: this.PageTitle, element_name: '播放' });
            }

            const musicPlayPromise = music_dom.play();

            if (musicPlayPromise !== undefined) {
              musicPlayPromise.then(() => {
                this.isAudioPlay = true;
              });
            }
          } else {
            if (isNeedReport) {
              this.$track({ page_title: this.PageTitle, element_name: '暂停' });
            }
            if (this.isAudioPlay) {
              music_dom.pause();
              this.isAudioPlay = false;
            }
          }
        } else {
          let video_dom = document.getElementById('video_dom');
          if (video_dom.paused) {
            if (isNeedReport) {
              this.$track({ page_title: this.PageTitle, element_name: '播放' });
            }
            const videoPlayPromise = video_dom.play();

            if (videoPlayPromise !== undefined) {
              videoPlayPromise.then(() => {
                this.isAudioPlay = true;
              });
            }
          } else {
            if (arg === true) return;
            if (isNeedReport) {
              this.$track({ page_title: this.PageTitle, element_name: '暂停' });
            }
            if (this.isAudioPlay) {
              video_dom.pause();
              this.isAudioPlay = false;
            }
          }
        }
      },
      /**
       * @description 下载app
       */

      openApp() {
        console.log(`index openApp`);
        this.$refs.ShareLandingCom.openApp();
      },
    },
  };
</script>
